@import url("./fonts/fonts.css");



.presents_container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding:10px;
}


.presents_container p{
  font-family: 'Condition';
  font-size: 1.5rem;
  max-width:30vw;
  color:white;
}

.presents_image{
  max-width: 10vw;
  margin:10px;
}

.main_banner_background{
  width: 100vw;
  object-fit: cover;
  position: absolute;
  z-index: 0;
  overflow: hidden;
  opacity: 0;
}

.left_border_img{
  position: fixed;
  height: 100vh;
  left:0;
}

.right_border_img{
  position: fixed;
  height: 100vh;
  right:0;
}

.main_banner{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.main_banner_content{
  padding-top:50px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}

.main_banner_book{
  max-width:50vw;
  max-height: 800px;
  
}

.book_title{
  font-family: 'Condition';
  text-align: center;
  font-size: 2rem;
  color:rgb(0, 255, 242);
  font-weight: bold;
}

.book_tagline{
  text-align: center;
  font-size: 2.5rem;
  margin-bottom:5px;
  max-width: 70vw;
}

.book_tagline span{
  font-size: 140%;
}

.book_subtag{
  font-size: 2.3rem !important;
}

.book_description{
  text-align: center;
  font-size: 16px;
}

.book_button{
  cursor: pointer;
  border-radius: 10px;
  padding:10px;
  font-family: 'Condition';
  color: white;
  font-size: 200%;
  box-shadow: 5px 5px 5px rgba(59, 187, 0, 0.295);
  text-decoration: none;
}

.book_button:hover{
   opacity: .8;
   font-size: 210%;
} 

.preorder_button{
  background-color: rgb(121, 230, 121);
}

.book_button_container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.quotes_container{
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  margin-top:10px;
  position: relative;
  width: 100vw;

}

.quote{
  font-size: 5rem;
  text-align: center;
  padding:10px;
  max-width: 80vw;
  padding-left:5vw;
  padding-right:5vw;
  background-color: white;
}

/* PAGE ELEMENTS */

.header{
  background-color: black;
  position: fixed;
  width: 100vw;
  height: 10vh;
  z-index: 10;
}

.parg{
  font-size: 24px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.info_container{
  position: relative;
  background-color: white;
  max-width: 50vw;
}


.page_content{
  margin-top: 10vh;
}

.footer{
  margin-top:25px;
  box-shadow: white 0px -10px 10px 25px;
  position: relative;
  bottom:0;
  background-color: white;
}

.footer_image{
  width: 100vw;
  object-fit: cover;
  position: relative;
  z-index: 0;
  background-color: white;
}

.App{
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  font-family: 'Fjalla';
}

.right-align{
  text-align: right;
}

.instagrams_container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.instagram{
  max-width: 45vw;
  margin:2.5vw;
}

@media (max-width:801px) {
  .left_border_img, .right_border_img{
    display: none;
  }
  .quote{
    font-size: 35px;
  }
  .presents_container{
    flex-direction: column;
    background-color: black;
  }
  .presents_container p{
    font-size: 16px;
    width: 80vw;
    max-width: 100vw;
    text-align: center !important;
  }
  .presents_image{
    max-width: 80vw;
  }
  .header{
    height: unset;
    position: relative;
  }
  .page_content{
    margin-top: 10px;
  }
  .main_banner_book, .info_container{
    max-width: 80vw;
  }
  .parg{
    font-size: 14px;
  }
  .last_quote{
    font-size: 35px !important;
  }
  .instagrams_container{
    flex-direction: column;
  }
  .instagram{
    max-width: unset;
    width: 80vw !important;
  }
  .instagram blockquote{
    width: 80vw !important;
  }
  .book_tagline{
    max-width: 90vw;
  }
  .book_subtag{
    font-size: 24px !important;
  }
  .book_button{
    font-size: 20px;
  }
}

@media (min-width:801px) {
  .quote{
    max-width: 50vw;
  }
}






