@font-face {
    font-family: 'Fjalla';
    src: url(./FjallaOne-Regular.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'Condition';
    src: url(./Condition-Regular.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'ArgentumSans-SemiBold';
    src: url(./ArgentumSans-SemiBold.woff2) format('woff2');
  }
   